.form {
    width: 700px;
    height: 640px;
}

.custom-scroll {
    overflow-y: auto;
  }
  
  .custom-scroll::-webkit-scrollbar {
    width: 8px;
    background-color: #D9D9D9;
  }
  
  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: #B5B5B5;
    border-radius: 4px;
  }
  
  .custom-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #B5B5B5;
  }