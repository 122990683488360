

.board {
    display: flex !important;
    flex-direction: row !important;         
}

.route {
    padding: 0.5rem;
}

.unit {
    padding: 0.5rem;
}

@media screen and (max-width: 960px) {
    .board {
        width: 100% !important;
        flex-direction: column !important;
    }

    .route {
        max-width: 100% !important;
    }

    .unit {
        max-width: 100% !important;
    }
 }